<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담일지관리</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <select  v-model="idxCrmCenter" @change="getReportList()" class="w-150px" :disabled="(isAdmin || isMaster) ? false : true">
                                <option value="-1">센터선택</option>
                                <option value="0">본원센터</option>
                                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>
                            <input type="text" v-model="value" @keyup.enter="getReportList" class="w-200px h-40px pd-00 pl-20" placeholder="상담사명/고객명">
                            <a @click="getReportList()" class="btn_search ml-10 mr-20 pointer">Search<span></span></a>
                        </div>
                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div class="float-right dp-block mb-10">예약일검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getReportList()">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>일지등록날짜</th>
                                <th>상담센터</th>
                                <th>상담사</th>
                                <th>고객</th>
                                <th>유형/기업명</th>
                                <th>위험단계</th>
                                <th>상담구분</th>
                                <th>상담주제</th>
                                <th>상담현황</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="11">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            
                            <tr>
                                <th class="w-20px">No</th>
                                <th class="w-70px">등록날짜</th>
                                <th class="w-60px">상담센터</th>
                                <th class="w-60px">상담사</th>
                                <th class="w-60px">고객</th>
                                <th class="w-80px">유형/기업명</th>
                                <th class="w-60px">위험단계</th>
                                <th class="w-60px">상담구분</th>
                                <th class="w-200px">상담주제</th>
                                <th class="w-100px">상담현황</th>

                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of reportList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{dateCheck(item.consDate2)}}</td>
                                <td>{{item.centerName}}</td>
                                <td class="underline pointer font_color" @click="staff(item.reservationIdxCrmStaff)">{{item.staffName}}</td>
                                <td class="underline pointer font_color" @click="client(item.idxCrmClient)">{{item.clientName}}</td>
                                <td>{{item.companyName}}</td>
                                <td>{{item.dangerStepTitle}}</td>
                                <td>{{item.titleType}}</td>
                                <td>{{item.titleAgendaFirst }} / {{ item.titleAgendaSecond}}</td>
                                <td>{{item.reservationStatus}}</td>
                                <td>
                                    <!-- 미등록일 경우 -->
                                    <span v-if="item.reportYn === '미등록'">미등록</span>
                                    <!-- -->
                                    <div v-else>
                                        <span class="underline pointer" @click="detail(item.idxReservation)">상세보기</span>
                                        |
                                        <span class="underline pointer" @click="requestModify(item)">수정요청</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="reportList.length === 0">
                                <td colspan="11">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜

        reportList: [],
        permission: '',
        value: '',
        reportYn: '',
        type: 'REPORT',

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
        idxCrmCenter: localStorage.getItem('idxCrmCenter'), 
        centerList: [],
        isMaster: false,
        isAdmin: false,
    }),

    created(){
        if (localStorage.getItem('auth') === 'MASTER') {
            this.isMaster = true
        } else {
            this.isMaster = false
        }

        if(localStorage.getItem('auth') === 'ADMIN'){
            this.isAdmin = true;
        }
        else{
            this.isAdmin = false;
        }
        this.getCenterListAll();
    },

    mounted() {

        if(sessionStorage.getItem('origin_journey_pageNum') !== 'undefined' && sessionStorage.getItem('origin_journey_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_journey_pageNum'))
        }
        if(sessionStorage.getItem('origin_journey_value') !== 'undefined' && sessionStorage.getItem('origin_journey_value') !== null) {
            this.value = sessionStorage.getItem('origin_journey_value')
        }
        if(sessionStorage.getItem('origin_journey_startDate') !== 'undefined' && sessionStorage.getItem('origin_journey_startDate') !== null) {
            this.startDate = sessionStorage.getItem('origin_journey_startDate')
        }
        if(sessionStorage.getItem('origin_journey_endDate') !== 'undefined' && sessionStorage.getItem('origin_journey_endDate') !== null) {
            this.endDate = sessionStorage.getItem('origin_journey_endDate')
        }
        if(sessionStorage.getItem('origin_journey_permission') !== 'undefined' && sessionStorage.getItem('origin_journey_permission') !== null) {
            this.permission = sessionStorage.getItem('origin_journey_permission')
        }
        this.getReportList();
    },

    methods: {
        dateCheck(date) {
            if (date !== null) {
                return date.slice(0, 10)
            } else {
                return '-'
            }
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getReportList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getReportList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getReportList()
            }
        },

        // 상담일지 목록
        getReportList() {
            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                startDate: this.startDate,
                endDate: this.endDate,
                permission: this.permission,
                reportYn: this.reportYn,
                idxCrmCenter: this.idxCrmCenter
            }

            sessionStorage.setItem('origin_journey_pageNum', this.pageNum)
            sessionStorage.setItem('origin_journey_value', this.value)
            sessionStorage.setItem('origin_journey_startDate', this.formatDate(this.startDate))
            sessionStorage.setItem('origin_journey_endDate', this.formatDate(this.endDate))
            sessionStorage.setItem('origin_journey_permission', this.permission)
            this.loading = true;
            this.axios.get('/api/v1/cons/report/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.reportList = res.data.reportList

                        if (res.data.reportListCount) {
                            this.listLength = res.data.reportListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.reportList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        // 승인
        putPermission(idx) {

            this.idx = idx

            var params = new URLSearchParams();
            params.append('type', this.type)
            params.append('idx', this.idx)

            this.axios.put('/api/v1/admin/permission', params)
                .then(res => {
                    alert('승인 되었습니다.')
                    this.$router.go(this.$router.currentRoute)
                })
                .catch(err => {
                    console.log(err)
                    alert(err)
                })

        },

        // 상담일지상세보기 페이지로 이동
        detail(idx) {
            this.$router.push(`/origin/counselor_journey_info?idx=${idx}`);
        },

        // 상담사 차트
        staff (idx) {
            this.$router.push(`/origin/counselor_manage_card?idx=${idx}`)
        },

        // 고객 차트
        client (idx) {
            this.$router.push(`/origin/customer_manage_chart?idx=${idx}`)
        },

        /**
         * @description 
         */
        reConfirm(){

        },

        /**
         * @description : 수정요청
         */
        requestModify(item){
            if(confirm(`"${item.staffName}"에게 수정요청 하시겠습니까?`)){
                this.axios.put(`/api/v1/cons/report-request-modi/${item.idxReport}/Y`)
                .then((res)=>{
                    if(res.data.err === 0){
                        alert(`"${item.staffName}"에게 수정요청 하였습니다.`);
                    }else{
                        alert(`"${item.staffName}"에게 수정요청을 실패하였습니다.`);
                    }
                }).catch(err => {
                    console.error(err)
                    alert(err);
                })
            }
            
        },

        /**
         * @description : 센터리스트 전체 
         */
        getCenterListAll() {
            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    } else {
                        this.centerList = res.data.centerList
                        alert('센터리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
    }
}
</script>
<style lang="scss">
    .is-modify{
        display: inline;
    }
</style>